import { IBaseRequest } from '@/api/types';
import { Account } from '@/entities';

interface IGetPaymentMethodAccountsProps extends IBaseRequest {
  clientId: string;
  paymentMethodId: string;
  senderCurrencyId: string;
}

export async function getPaymentMethodAccounts({
  api,
  clientId,
  paymentMethodId,
  senderCurrencyId,
}: IGetPaymentMethodAccountsProps) {
  const response = await api.get<Account[]>(
    `clients/${clientId}/payment_methods/${paymentMethodId}/accounts`,
    { params: { senderCurrencyId } },
  );

  return response.data;
}
