import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/react';
import { ITextProps, TextKind } from '@/ui/components/desktop/Text/types.ts';

export const STYLES_BY_KIND: Record<TextKind, SerializedStyles> = {
  [TextKind.AMOUNT_BOLD]: css`
    font-size: 36px;
    font-weight: 600;
    line-height: 1.22;
    letter-spacing: 0.4px;
  `,
  [TextKind.AMOUNT_DEFAULT]: css`
    font-size: 36px;
    font-weight: 400;
    line-height: 1.22;
    letter-spacing: 0.4px;
  `,
  [TextKind.TITLE_1_BOLD]: css`
    font-size: 24px;
    font-weight: 600;
    line-height: 1.33;
    letter-spacing: 0.4px;
  `,
  [TextKind.TITLE_1_DEFAULT]: css`
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: initial;
  `,
  [TextKind.TITLE_2_BOLD]: css`
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    letter-spacing: 0.4px;
  `,
  [TextKind.TITLE_2_MEDIUM]: css`
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.4px;
  `,
  [TextKind.TITLE_2_DEFAULT]: css`
    font-size: 18px;
    font-weight: 400;
    line-height: 1.33;
    letter-spacing: 0.4px;
  `,
  [TextKind.TITLE_3_BOLD]: css`
    font-size: 16px;
    font-weight: 600;
    line-height: 1.375;
    letter-spacing: 0.4px;
  `,
  [TextKind.TITLE_3_MEDIUM]: css`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.375;
    letter-spacing: 0.4px;
  `,
  [TextKind.TITLE_3_DEFAULT]: css`
    font-size: 16px;
    font-weight: 400;
    line-height: 1.375;
    letter-spacing: initial;
  `,
  [TextKind.BODY_BOLD]: css`
    font-size: 14px;
    font-weight: 600;
    line-height: 1.286;
    letter-spacing: 0.4px;
  `,
  [TextKind.BODY_MEDIUM]: css`
    font-size: 14px;
    font-weight: 500;
    line-height: 1.286;
    letter-spacing: initial;
  `,
  [TextKind.BODY_DEFAULT]: css`
    font-size: 14px;
    font-weight: 400;
    line-height: 1.286;
    letter-spacing: initial;
  `,
  [TextKind.FOOTNOTE_BOLD]: css`
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    letter-spacing: 0.3px;
  `,
  [TextKind.FOOTNOTE_MEDIUM]: css`
    font-size: 12px;
    font-weight: 500;
    line-height: 1.28;
    letter-spacing: initial;
  `,
  [TextKind.FOOTNOTE_DEFAULT]: css`
    font-size: 12px;
    font-weight: 400;
    line-height: 1.28;
    letter-spacing: initial;
  `,
  [TextKind.CAPTION_BOLD]: css`
    font-size: 11px;
    font-weight: 600;
    line-height: 1.27;
    letter-spacing: 0.3px;
  `,
  [TextKind.CAPTION_MEDIUM]: css`
    font-size: 11px;
    font-weight: 500;
    line-height: 1.27;
    letter-spacing: 0.3px;
  `,
  [TextKind.CAPTION_DEFAULT]: css`
    font-size: 11px;
    font-weight: 400;
    line-height: 1.27;
    letter-spacing: initial;
  `,
  [TextKind.CAPTION_2_BOLD]: css`
    font-size: 10px;
    font-weight: 600;
    line-height: 1.1;
    letter-spacing: 0.2px;
  `,
  [TextKind.CAPTION_2_DEFAULT]: css`
    font-size: 10px;
    font-weight: 400;
    line-height: 1.1;
    letter-spacing: 0.2px;
  `,
  [TextKind.CAPTION_3_DEFAULT]: css`
    font-size: 9px;
    font-weight: 400;
    line-height: 11px;
  `,
};

export const StyledText = styled.p<ITextProps>(
  ({
    kind = TextKind.BODY_DEFAULT,
    color = 'var(--color-label-primary)',
    ellipsis,
    lineThrough,
  }) => css`
    margin: 0;
    padding: 0;

    font-family: var(--font-family);

    ${STYLES_BY_KIND[kind]};
    color: ${color};

    ${lineThrough &&
    css`
      text-decoration: line-through;
    `}

    ${ellipsis &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
  `,
);
